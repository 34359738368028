"use client";
import React, { ReactNode } from "react";
import { useTranslations } from 'next-intl';
import { motion } from "framer-motion";
import {
  HomeIcon,
  LightningBoltIcon,
} from "@radix-ui/react-icons";

const RibbonMarquee = () => {
  const t = useTranslations('IndexPage');

  return (
    <section className="section-xl mx-auto overflow-hidden relative global-padding-xl">
      <div className="h-full w-16 hidden xl:block bg-gradient-to-r from-brand-blue-dark absolute top-0 left-0 z-10" />
      <div className="h-full w-16 hidden xl:block bg-gradient-to-l from-brand-blue-dark absolute top-0 right-0 z-10" />

      <p className="mb-4 text-center intro-2 text-brand-selago">
        {t('ribbon1.title')}
      </p>

      <div className="flex translate-y-[50%] rotate-[10deg] md:rotate-[8deg] scale-110 overflow-hidden bg-brand-beige shadow-lg">
        {[...Array(4)].map((_, index) => (
          <TranslateWrapper key={index}>
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.1')} />
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.2')} />
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.3')} />
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.4')} />
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.5')} />
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.6')} />
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.7')} />
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.8')} />
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.9')} />
            <LogoItem Icon={HomeIcon} name={t('ribbon1.list.10')} />
          </TranslateWrapper>
        ))}
      </div>

      <div className="flex -translate-y-[50%] -rotate-[10deg] md:-rotate-[8deg] scale-110 overflow-hidden bg-brand-beige shadow-lg">
        {[...Array(4)].map((_, index) => (
          <TranslateWrapper key={index} reverse>
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.1')} />
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.2')} />
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.3')} />
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.4')} />
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.5')} />
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.6')} />
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.7')} />
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.8')} />
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.9')} />
            <LogoItem Icon={LightningBoltIcon} name={t('ribbon2.list.10')} />
          </TranslateWrapper>
        ))}
      </div>

      <p className="mt-4 text-center intro-2 text-brand-selago">
        {t('ribbon2.title')}
      </p>
    </section>
  );
};


const TranslateWrapper = ({
  children,
  reverse,
}: {
  children: ReactNode;
  reverse?: boolean;
}) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? "-100%" : "0%" }
      }
      animate={{ translateX: reverse ? "0%" : "-100%" }}
      transition={{ duration: 50, repeat: Infinity, ease: "linear" }}
      className="flex px-2"
    >
      {children}
    </motion.div>
  );
};

const LogoItem = ({ Icon, name }: { Icon: React.ElementType; name: string; }) => {
  return (
    <div
      className="flex items-center justify-center gap-4 px-4 py-2 md:py-4 text-brand-blue-reg transition-colors pointer-events-none"
    >
      <Icon className="text-2xl md:text-4xl" />
      <span className="whitespace-nowrap font-bold uppercase text-2xl md:text-3xl">
        {name}
      </span>
    </div>
  );
};

export default RibbonMarquee;