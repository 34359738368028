"use client";
import React, { useMemo, useState } from "react";
import { useSelectedLayoutSegment } from 'next/navigation';
import { useTranslations } from 'next-intl';
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
import { Menu, ChevronDown, X } from "lucide-react";
import NavLink from "./NavLink";
import DotExpandButton from "./ui/DotButton";
import LocaleSwitcher from "./LocaleSwitcher";



const NavigationV2 = ({ children }: { children: React.ReactNode }) => {
  const t = useTranslations('Navigation');
  const links = [
    {
      title: t('home'),
      href: "/",
    },
    // {
    //   title: t('about'),
    //   href: "/about",
    // },
    {
      title: t('websites'),
      href: "/websites",
    },
    // {
    //   title: t('webServices'),
    //   sublinks: [
    //     {
    //       title: t('websites'),
    //       href: "/websites",
    //     },
    //     {
    //       title: t('figmaToHtml'),
    //       href: "/figma-to-html",
    //     },
    //     {
    //       title: t('appDevelopment'),
    //       href: "/app-development",
    //     },
    //     {
    //       title: t('ecommerce'),
    //       href: "/ecommerce",
    //     },
    //   ],
    // },
    // {
    //   title: t('marketing'),
    //   sublinks: [
    //     {
    //       title: t('seoOptimization'),
    //       href: "/seo",
    //     },
    //     {
    //       title: t('seoAudit'),
    //       href: "/seo-audit",
    //     },
    //     {
    //       title: t('technicalSEO'),
    //       href: "/technical-seo",
    //     },
    //   ],
    // },
    {
      title: t('portfolio'),
      href: "/portfolio",
    },
    // {
    //   title: t('blog'),
    //   href: "/blog",
    // },
  ];

  return (
    <div className="w-full">
      <RoundedDrawerNav
        links={links}
        navBackground="bg-brand-blue-dark"
        bodyBackground="bg-brand-blue-dark"
      >
        <div className="w-full">
          {children}
        </div>
      </RoundedDrawerNav>
    </div>
  );
};

const RoundedDrawerNav = ({
  children,
  navBackground,
  bodyBackground,
  links,
}: {
  children: React.ReactNode,
  navBackground: string,
  bodyBackground: string,
  links: { title: string, href?: any, sublinks?: { title: string, href: any }[] }[],
}) => {
  const t = useTranslations('Navigation');
  const [hovered, setHovered] = useState<string | null>(null);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const activeSublinks = useMemo(() => {
    if (!hovered) return [];
    const link = links.find((l) => l.title === hovered);

    return link?.sublinks || [];
  }, [hovered, links]);

  return (
    <>
      <nav
        onMouseLeave={() => setHovered(null)}
        className={`${navBackground} global-padding-md py-3 overflow-hidden`}
      >
        <div className="flex items-top gap-x-3">
          <div className="w-full flex justify-start gap-x-12">
            <Logo setMobileNavOpen={setMobileNavOpen} />
            <DesktopLinks
              links={links}
              setHovered={setHovered}
              hovered={hovered}
              activeSublinks={activeSublinks}
            />
          </div>
          <LocaleSwitcher />
          <DotExpandButton
            href={'/contact'}
            text={t('contactUs')}
            style="navCTA"
            onClick={() => setMobileNavOpen(false)}
          />
          <button
            aria-label={mobileNavOpen ? t('toggleMenu.close') : t('toggleMenu.open')}
            onClick={() => setMobileNavOpen((pv) => !pv)}
            className="block text-2xl text-gray-200 md:hidden"
          >
            {mobileNavOpen ? <X className="h-8 w-8" /> : <Menu className="h-8 w-8" />}
          </button>
        </div>
        <MobileLinks links={links} open={mobileNavOpen} setMobileNavOpen={setMobileNavOpen} />
      </nav>
      <motion.main layout className={`${navBackground}`}>
        <div className={`${bodyBackground}`}>
          {children}
        </div>
      </motion.main>
    </>
  );
};

const Logo = ({ setMobileNavOpen }: { setMobileNavOpen: (open: boolean) => void, }) => {
  return (
    <NavLink
      href="/"
      className="w-48 h-10 md:w-10 lg:w-48 xl:w-60 md:h-10 relative overflow-hidden transition-all"
      onClick={() => setMobileNavOpen(false)}
    >
      <Image src="/images/colmena-digital-logo-dark.svg" priority alt="Colmena Digital Logo" fill className="md:hidden lg:block object-contain" />
      <Image src="/images/colmena-digital-logo-icon.svg" priority alt="Colmena Digital Logo" fill className="hidden md:block lg:hidden object-contain" />
    </NavLink>
  );
};

const DesktopLinks = ({
  links,
  setHovered,
  hovered,
  activeSublinks,
}: {
  links: { title: string, href?: any, sublinks?: { title: string, href: any }[] }[],
  setHovered: (title: string | null) => void,
  hovered: string | null,
  activeSublinks: { title: string, href: any }[],
}) => {
  return (
    <div className="hidden md:block">
      <div className="flex gap-4 lg:gap-6">
        {
          links.map((l) => (
            <TopLink
              key={l.title}
              setHovered={setHovered}
              title={l.title}
              href={l.href}
              sublinks={l.sublinks}
            >
              {l.title}
            </TopLink>
          ))
        }
      </div>
      <AnimatePresence mode="popLayout">
        {hovered && (
          <motion.div
            initial={
              {
                opacity: 0,
              }
            }
            animate={{
              opacity: 1,
            }
            }
            exit={{
              opacity: 0,
            }}
            className="space-y-3 py-6"
          >
            {
              activeSublinks.map((l) => (
                <NavLink
                  className="top-nav-link block text-xl lg:text-2xl text-white transition-colors hover:text-brand-yellow tracking-tight"
                  href={l.href}
                  key={l.title}
                >
                  {l.title}
                </NavLink>
              ))
            }
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const MobileLinks = ({
  links,
  open,
  setMobileNavOpen,
}: {
  links: { title: string, href?: any, sublinks?: { title: string, href: any }[] }[],
  open: boolean,
  setMobileNavOpen: (open: boolean) => void,
}) => {
  const mainClasses = "text-lg text-brand-yellow flex items-center tracking-tight";
  const mainClassesLink = "cursor-pointer text-lg text-white transition-colors hover:text-brand-yellow flex items-center";
  const subLinksClasses = "cursor-pointer text-lg block text-white hover:text-brand-yellow transition-colors";

  return (
    <AnimatePresence mode="popLayout">
      {open && (
        <motion.div
          initial={
            {
              opacity: 0,
            }
          }
          animate={{
            opacity: 1,
          }
          }
          exit={{
            opacity: 0,
          }}
          className="grid grid-cols-1 gap-4 py-6 px-6 md:hidden"
        >
          {
            links.map((l) => {
              return (
                <div key={l.title} className="flex flex-col space-y-4">
                  {l.href ? (
                    <NavLink
                      href={l.href}
                      className={mainClassesLink}
                      onClick={() => setMobileNavOpen(false)}
                    >
                      {l.title}
                    </NavLink>
                  ) : (
                    <span
                      className={mainClasses}
                    >
                      {l.title}
                      <ChevronDown className="h-4 w-4 ml-1" />
                    </span>
                  )}

                  {l.sublinks && (
                    <div className="w-full flex flex-col gap-y-4 bg-brand-blue-shade-1 p-4 global-rounded">
                      {l.sublinks.map((sl) => (
                        <NavLink
                          className={subLinksClasses}
                          href={sl.href}
                          key={sl.title}
                          onClick={() => setMobileNavOpen(false)}
                        >
                          {sl.title}
                        </NavLink>
                      ))
                      }
                    </div>
                  )}
                </div>
              );
            })}
        </motion.div>
      )
      }
    </AnimatePresence>
  );
};

const TopLink = ({
  children,
  setHovered,
  title,
  href,
  sublinks,
}: {
  children: React.ReactNode,
  setHovered: (title: string | null) => void,
  title: string,
  href?: any,
  sublinks?: { title: string, href: any }[],
}) => {
  const mainClass = "top-nav-link cursor-pointer text-base text-white transition-colors hover:text-brand-yellow h-10 flex items-center";
  const alternativeClass = "top-nav-link cursor-pointer hidden lg:flex text-base text-white transition-colors hover:text-brand-yellow h-10 flex items-center";
  let selectedClasses = mainClass;
  const optionalLinks = [
    "/",
    "/blog"
  ];

  const selectedLayoutSegment = useSelectedLayoutSegment();
  const pathname = selectedLayoutSegment ? `/${selectedLayoutSegment}` : '/';
  const isActive = href ? pathname === href : sublinks?.some((sublink) => pathname === sublink.href);

  if (isActive) {
    selectedClasses += " !text-brand-yellow";
  }

  if (href) {
    if (optionalLinks.includes(href)) {
      selectedClasses = alternativeClass;
    }

    return (
      <NavLink
        href={href}
        onMouseEnter={() => setHovered(null)}
        className={selectedClasses}
      >
        {children}
      </NavLink >
    );
  }

  return (
    <span
      onMouseEnter={() => setHovered(title)}
      className={selectedClasses}
    >
      {children}
      <ChevronDown className="h-4 w-4 ml-1" />
    </span>
  )
};

export default NavigationV2;