'use client'
import { useTranslations } from 'next-intl';
// import ScrollTextEffect from '@/components/ui/ScrollTextEffect';

export default function AgencyIntro() {
  const t = useTranslations('IndexPage');

  return (
    <div className="overflow-hidden">
      <div className="global-padding-xl section-md mx-auto flex flex-col items-center justify-center global-gap">
        <h2 className='heading-3 text-white text-center uppercase text-pretty'>
          {t('HomeUSP.title')}
        </h2>
        <p className='heading-4 md:heading-5 text-white text-center'>
          {t('HomeUSP.description')}
          {/* <ScrollTextEffect paragraph={t('HomeUSP.description')} /> */}
        </p>
      </div>
    </div>
  )
}
