'use client';
import React, { useState, useEffect } from 'react';
import { ArrowUp } from 'lucide-react';
import { useTranslations } from 'next-intl';

const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const t = useTranslations('Common');

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <button
      className={`scrollToTopButton group ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    >
      <ArrowUp className="inline-block md:h-6 md:w-6" />
      <span className='sr-only'>{t('scrollTop')}</span>
    </button>
  );
};

export default ScrollTop;
