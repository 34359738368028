"use client";
import React from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";

import { useTranslations } from 'next-intl';

import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
const LottiePlayer = dynamic(() => import('react-lottie-player'), { ssr: false });

import { AnimatedBeamTech } from "@/components/ui/AnimatedBeam";
import { AnimatedList } from "@/components/ui/AnimatedList";
import { Marquee } from "@/components/ui/Marquee";
import PhoneNotification from "./ui/PhoneNotification";
import VideoComponent from "./ui/VideoComponent";
import privacyGraphic from '@/components/animations/privacy.json';
import { BentoCard, BentoGrid } from "@/components/ui/BentoGrid";


export default function BentoBox() {
  const { ref, inView } = useInView({ triggerOnce: true });
  const t = useTranslations('IndexPage');
  const industries = [
    {
      name: t('BentoBox.industries.1.name'),
      icon: '/images/homepage/industries/professionals.svg',
      list: [
        t('BentoBox.industries.1.list.1'),
        t('BentoBox.industries.1.list.2'),
        t('BentoBox.industries.1.list.3'),
        t('BentoBox.industries.1.list.4'),
      ]
    },
    {
      name: t('BentoBox.industries.2.name'),
      icon: '/images/homepage/industries/real-estate.svg',
      list: [
        t('BentoBox.industries.2.list.1'),
        t('BentoBox.industries.2.list.2'),
        t('BentoBox.industries.2.list.3'),
        t('BentoBox.industries.2.list.4'),
        t('BentoBox.industries.2.list.5'),
      ]
    },
    {
      name: t('BentoBox.industries.3.name'),
      icon: '/images/homepage/industries/healthcare.svg',
      list: [
        t('BentoBox.industries.3.list.1'),
        t('BentoBox.industries.3.list.2'),
        t('BentoBox.industries.3.list.3'),
        t('BentoBox.industries.3.list.4'),
        t('BentoBox.industries.3.list.5'),
        t('BentoBox.industries.3.list.6'),
        t('BentoBox.industries.3.list.7'),
      ]
    },
    {
      name: t('BentoBox.industries.4.name'),
      icon: '/images/homepage/industries/retail.svg',
      list: [
        t('BentoBox.industries.4.list.1'),
        t('BentoBox.industries.4.list.2'),
        t('BentoBox.industries.4.list.3'),
        t('BentoBox.industries.4.list.4'),
        t('BentoBox.industries.4.list.5'),
      ]
    },
    {
      name: t('BentoBox.industries.5.name'),
      icon: '/images/homepage/industries/startups.svg',
      list: [
        t('BentoBox.industries.5.list.1'),
        t('BentoBox.industries.5.list.2'),
        t('BentoBox.industries.5.list.3'),
        t('BentoBox.industries.5.list.4'),
      ]
    },
    {
      name: t('BentoBox.industries.6.name'),
      icon: '/images/homepage/industries/restaurant.svg',
      list: [
        t('BentoBox.industries.6.list.1'),
        t('BentoBox.industries.6.list.2'),
        t('BentoBox.industries.6.list.3'),
        t('BentoBox.industries.6.list.4'),
        t('BentoBox.industries.6.list.5'),
        t('BentoBox.industries.6.list.6'),
        t('BentoBox.industries.6.list.7'),
        t('BentoBox.industries.6.list.8'),
      ]
    },
  ];

  let notifications = [
    {
      name: t('BentoBox.notifications.1.name'),
      description: t('BentoBox.notifications.1.description'),
      time: t('BentoBox.notifications.1.time'),
      icon: "📈",
      color: "#00C9A7",
    },
    {
      name: t('BentoBox.notifications.2.name'),
      description: t('BentoBox.notifications.2.description'),
      time: t('BentoBox.notifications.2.time'),
      icon: "🎯",
      color: "#FFB800",
    },
    {
      name: t('BentoBox.notifications.3.name'),
      description: t('BentoBox.notifications.3.description'),
      time: t('BentoBox.notifications.3.time'),
      icon: "📊",
      color: "#FF3D71",
    },
    {
      name: t('BentoBox.notifications.4.name'),
      description: t('BentoBox.notifications.4.description'),
      time: t('BentoBox.notifications.4.time'),
      icon: "🔍",
      color: "#1E86FF",
    },
    {
      name: t('BentoBox.notifications.5.name'),
      description: t('BentoBox.notifications.5.description'),
      time: t('BentoBox.notifications.5.time'),
      icon: "🗞️",
      color: "#1E86FF",
    },
    {
      name: t('BentoBox.notifications.6.name'),
      description: t('BentoBox.notifications.6.description'),
      time: t('BentoBox.notifications.6.time'),
      icon: "⚡",
      color: "#1E86FF",
    },
    {
      name: t('BentoBox.notifications.7.name'),
      description: t('BentoBox.notifications.7.description'),
      time: t('BentoBox.notifications.7.time'),
      icon: "🎯",
      color: "#1E86FF",
    },
  ];

  notifications = Array.from({ length: 10 }, () => notifications).flat();

  const features = [
    {
      IconImg: "/images/homepage/experts.svg",
      name: t('BentoBox.expertTeam.title'),
      description: t('BentoBox.expertTeam.description'),
      className: "col-span-3 md:col-span-2",
      background: (
        <Marquee
          pauseOnHover
          className="absolute p-0 top-4 [--duration:20s] [mask-image:linear-gradient(to_top,transparent_20%,#000_100%)] "
        >
          {industries.map((item, idx) => (
            <figure
              key={idx}
              className={cn(
                "relative w-44 cursor-pointer overflow-hidden rounded-xl border p-4",
                "border bg-white hover:border-brand-blue-reg",
                "transform-gpu blur-[1px] transition-all duration-300 ease-out hover:blur-none",
              )}
            >
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-col gap-2">
                  <div className="w-12 h-12 relative">
                    <Image src={item.icon} alt={item.name} fill className="object-contain" />
                  </div>
                  <figcaption className="text-sm font-bold text-brand-blue-mid">
                    {item.name}
                  </figcaption>
                </div>
              </div>
              {item.list &&
                <ul className="mt-2 text-xs text-gray-500">
                  {item.list.map((subitem, idx) => (
                    <li key={idx}>{subitem}</li>
                  ))}
                </ul>
              }
            </figure>
          ))}
        </Marquee>
      ),
    },
    {
      IconImg: "/images/homepage/strategy.svg",
      name: t('BentoBox.strategicSEO.title'),
      description: t('BentoBox.strategicSEO.description'),
      className: "col-span-3 md:col-span-1",
      background: (
        <AnimatedList
          className="w-full absolute px-6 top-4 -right-14 h-[300px] w-full border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_45%,#000_100%)] group-hover:scale-105"
        >
          {notifications.map((item, idx) => (
            <PhoneNotification {...item} key={idx} />
          ))}
        </AnimatedList>
      ),
    },
    {
      IconImg: "/images/homepage/speed.svg",
      name: t('BentoBox.provenSpeed.title'),
      description: t('BentoBox.provenSpeed.description'),
      className: "col-span-3 md:col-span-1",
      background: (
        <div
          className="w-[90%] rounded-xl overflow-hidden absolute -right-4 top-4 origin-top rounded-md border transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_100%)] group-hover:scale-105"
        >
          <VideoComponent webmSrc="/videos/pagespeed.webp" mp4Src="/videos/pagespeed.mp4" className="object-cover rounded-xl relative overflow-hidden" />
        </div>
      ),
    },
    {
      IconImg: "/images/homepage/tech.svg",
      name: t('BentoBox.technology.title'),
      description: t('BentoBox.technology.description'),
      className: "col-span-3 md:col-span-1",
      background: (
        <AnimatedBeamTech className="absolute -right-2 top-4 w-2/3 h-[300px] transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_30%,#000_100%)] group-hover:scale-105" />
      ),
    },
    {
      IconImg: "/images/homepage/solutions.svg",
      name: t('BentoBox.reliability.title'),
      description: t('BentoBox.reliability.description'),
      className: "col-span-3 md:col-span-1",
      background: (
        <div
          className="absolute right-0 top-4 origin-top rounded-xl bg-white border transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_100%)] group-hover:scale-105"
        >
          <LottiePlayer animationData={privacyGraphic} play loop={true} className="object-contain scale-125 h-64 w-64" />
        </div>
      ),
    },
  ];

  return (
    <>
      <div ref={ref} className='bg-white w-full relative global-padding'>
        <div className="section-lg mx-auto flex flex-col global-gap">
          <h2 className="heading-4 mx-auto text-center text-brand-blue-mid">
            {t('BentoBox.title')}
          </h2>
          {inView && (
            <BentoGrid>
              {features.map((feature, idx) => (
                <BentoCard key={idx} {...feature} />
              ))}
            </BentoGrid>
          )}
        </div>
      </div>
    </>
  );
}

