"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { useTranslations } from 'next-intl';

import { ArrowRight } from "lucide-react";
import Pill from "@/components/ui/Pill";
import SplashButton from "@/components/ui/SplashButton";
import GhostButton from "@/components/ui/GhostButton";


const HomeStatement = () => {
  return (
    <section className="relative w-full global-rounded overflow-hidden shadow-xl">
      <div className="mx-auto bg-brand-blue-reg global-padding-lg">
        <Content />
        <Beams />
        <GradientGrid />
      </div>
    </section>
  );
};

const Content = () => {
  const t = useTranslations('IndexPage');

  return (
    <div className="section-lg mx-auto relative z-20 flex flex-col items-center justify-center global-gap-xl global-padding-xl">
      <motion.div
        initial={{
          y: 25,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1.25,
          ease: "easeInOut",
        }}
        className="relative"
      >
        <Pill text={t('hero.eyeBrow')} />
      </motion.div>
      <div className="flex flex-col global-gap-sm">
        <motion.h1
          initial={{
            y: 25,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 1.25,
            delay: 0.25,
            ease: "easeInOut",
          }}
          className="heading-1 text-center text-balanced text-white"
        >
          {t('hero.title')}
          <span className="block text-brand-yellow text-balanced">{t('hero.title2')}</span>
        </motion.h1>
        <motion.p
          initial={{
            y: 25,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 1.25,
            delay: 0.5,
            ease: "easeInOut",
          }}
          className="intro-1 text-center text-white text-balanced"
        >
          {t('hero.description')}
        </motion.p>
      </div>
      <motion.div
        initial={{
          y: 25,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1.25,
          delay: 0.75,
          ease: "easeInOut",
        }}
        className="flex flex-col items-center global-gap sm:flex-row"
      >
        <SplashButton
          href="/websites"
          className="flex items-center gap-2"
        >
          {t('hero.mainCta')}
          <ArrowRight />
        </SplashButton>
        <GhostButton
          href="/contact"
        >
          {t('hero.secondaryCta')}
        </GhostButton>
      </motion.div>
    </div>
  );
};

const Beams = () => {
  const { width } = useWindowSize();

  const numColumns = width ? Math.floor(width / GRID_BOX_SIZE) : 0;

  const placements = [
    {
      top: GRID_BOX_SIZE * 0,
      left: Math.floor(numColumns * 0.05) * GRID_BOX_SIZE,
      transition: {
        duration: 3.5,
        repeatDelay: 5,
        delay: 2,
      },
    },
    {
      top: GRID_BOX_SIZE * 12,
      left: Math.floor(numColumns * 0.15) * GRID_BOX_SIZE,
      transition: {
        duration: 3.5,
        repeatDelay: 10,
        delay: 4,
      },
    },
    {
      top: GRID_BOX_SIZE * 3,
      left: Math.floor(numColumns * 0.25) * GRID_BOX_SIZE,
    },
    {
      top: GRID_BOX_SIZE * 9,
      left: Math.floor(numColumns * 0.75) * GRID_BOX_SIZE,
      transition: {
        duration: 2,
        repeatDelay: 7.5,
        delay: 3.5,
      },
    },
    {
      top: 0,
      left: Math.floor(numColumns * 0.7) * GRID_BOX_SIZE,
      transition: {
        duration: 3,
        repeatDelay: 2,
        delay: 1,
      },
    },
    {
      top: GRID_BOX_SIZE * 2,
      left: Math.floor(numColumns * 1) * GRID_BOX_SIZE - GRID_BOX_SIZE,
      transition: {
        duration: 5,
        repeatDelay: 5,
        delay: 5,
      },
    },
  ];

  return (
    <>
      {placements.map((p, i) => (
        <Beam
          key={i}
          top={p.top}
          left={p.left - BEAM_WIDTH_OFFSET}
          transition={p.transition || {}}
        />
      ))}
    </>
  );
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};

const Beam = ({ top, left, transition = {} }: { top: any, left: any, transition: any }) => {
  return (
    <motion.div
      initial={{
        y: 0,
        opacity: 0,
      }}
      animate={{
        opacity: [0, 1, 0],
        y: 32 * 8,
      }}
      transition={{
        ease: "easeInOut",
        duration: 3,
        repeat: Infinity,
        repeatDelay: 1.5,
        ...transition,
      }}
      style={{
        top,
        left,
      }}
      className="absolute z-10 h-[64px] w-[2px] bg-gradient-to-b from-brand-yellow/0 to-brand-yellow"
    />
  );
};

const GradientGrid = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 2.5,
        ease: "easeInOut",
      }}
      className="absolute inset-0 z-0"
    >
      <div
        style={{
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='22' height='22' fill='none' stroke-width='2' stroke='rgb(30 58 138 / 0.5)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e")`,
        }}
        className="absolute inset-0 z-10"
      />
      <div className="absolute inset-0 z-4 bg-gradient-to-b from-brand-blue-reg to-brand-blue-dark" />
    </motion.div>
  );
};

const GRID_BOX_SIZE = 22;
const BEAM_WIDTH_OFFSET = 1;

export default HomeStatement;