import { twMerge } from "tailwind-merge";
import { Link } from '@/i18n/routing';

const SplashButton = ({ href, children, className, ...rest }: { href: any, children: any, className: string }) => {
  return (
    <Link
      href={href}
      className={
        twMerge(
          "rounded-4xl bg-brand-yellow px-8 py-2 text-brand-blue-dark font-bold transition-all hover:scale-[1.02] active:scale-[0.98] hover:bg-brand-blue-dark hover:text-brand-yellow",
          className
        )
      }
      {...rest}
    >
      {children}
    </Link>
  );
};

export default SplashButton;