"use client";
import React from "react";

import { Link } from '@/i18n/routing';
import { useTranslations } from 'next-intl';
import Image from "next/image";

export default function HomeServices() {
  const t = useTranslations('IndexPage');

  const services = [
    {
      id: "web-development",
      title: t('services.webDevelopment.title'),
      description: t('services.webDevelopment.description'),
      icon: "/images/services/colmena-icon-websites.svg",
      services: [
        {
          id: "web-design",
          title: t('services.webDevelopment.list.1.name'),
          description: t('services.webDevelopment.list.1.description'),
          icon: "/images/services/colmena-icon-websites.svg",
          link: "/websites",
          linkText: t('services.webDevelopment.list.1.cta'),
        },
        {
          id: "web-development",
          title: t('services.webDevelopment.list.2.name'),
          description: t('services.webDevelopment.list.2.description'),
          icon: "/images/services/colmena-icon-apps.svg",
          link: "/contact",
          linkText: t('services.webDevelopment.list.2.cta'),
        },
        {
          id: "e-commerce",
          title: t('services.webDevelopment.list.3.name'),
          description: t('services.webDevelopment.list.3.description'),
          icon: "/images/services/colmena-icon-ecommerce.svg",
          link: "/contact",
          linkText: t('services.webDevelopment.list.3.cta'),
        },
      ],
    },
    {
      id: "seoServices",
      title: t('services.seoServices.title'),
      description: t('services.seoServices.description'),
      icon: "/images/services/colmena-icon-websites.svg",
      services: [
        {
          id: "seoOptimization",
          title: t('services.seoServices.list.1.name'),
          description: t('services.seoServices.list.1.description'),
          icon: "/images/services/colmena-icon-ranking_1.svg",
          link: "/contact",
          linkText: t('services.seoServices.list.1.cta'),
        },
        {
          id: "seoAudit",
          title: t('services.seoServices.list.2.name'),
          description: t('services.seoServices.list.2.description'),
          icon: "/images/services/colmena-icon-optimization.svg",
          link: "/contact",
          linkText: t('services.seoServices.list.2.cta'),
        },
        {
          id: "consulting",
          title: t('services.seoServices.list.3.name'),
          description: t('services.seoServices.list.3.description'),
          icon: "/images/services/colmena-icon-consulting.svg",
          link: "/contact",
          linkText: t('services.seoServices.list.3.cta'),
        },
      ],
    },
  ];

  return (
    <section className="bg-gradient-to-b from-brand-blue-dark to-brand-blue-reg global-padding-xl global-rounded-b">
      <div className="section-lg mx-auto relative flex flex-col global-gap">
        <h2 className="heading-4 text-center text-brand-yellow">
          {t('services.title')}
        </h2>

        <div className="mx-auto relative flex flex-col global-gap">
          {services.map((service) => (
            <ServiceGroup key={service.id} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
}

const ServiceGroup = ({
  id,
  title,
  description,
  services,
}: {
  id: string;
  title: string;
  description: string;
  services: {
    id: string;
    title: string;
    description: string;
    link?: string | null;
    linkText?: string | null;
  }[];
}) => {
  return (
    <div key={id} className="group global-rounded-md grid md:grid-cols-2 items-center global-gap-xl global-padding bg-brand-beige relative overflow-hidden shadow-xl">
      <div className="flex flex-col items-center global-gap-sm text-center relative z-1">
        <h3 className="heading-3 text-brand-blue-mid">{title}</h3>
        <p className="intro-3 text-gray-500">{description}</p>
      </div>

      <div className="flex flex-col global-gap-sm relative z-1">
        {services.map((subService) => (
          <ServiceCard key={subService.id} {...subService} />
        ))}
      </div>
    </div>
  );
};

const ServiceCard = ({
  title,
  description,
  icon,
  link,
  linkText,
}: {
  title: string;
  description: string;
  icon?: string;
  link?: any;
  linkText?: string | null;
}) => {
  return (
    <div
      className="bg-white global-rounded-sm global-padding"
    >
      <div className="flex flex-col global-gap-xs">
        <div className="flex flex-row items-center global-gap-xs justify-between">

          <h4 className="heading-5 text-brand-blue-mid">{title}</h4>
          {icon && (
            <Image src={icon} alt={title} width={50} height={50} />
          )}
        </div>
        <p className='text-gray-500'>{description}</p>
        {link && linkText && (
          <Link
            href={link}
            className="text-brand-blue-mid font-bold"
          >
            {linkText}
          </Link>
        )}
      </div>
    </div>
  );
};
