"use client";
import React from "react";
import localFont from 'next/font/local';
const TTCommons = localFont({
  variable: '--TTCommons',
  src: [
    {
      path: '../../public/fonts/TT_Commons_Pro_Normal.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../public/fonts/TT_Commons_Pro_Normal_Italic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../../public/fonts/TT_Commons_Pro_Bold.woff2',
      weight: 'bold',
      style: 'normal',
    },
    {
      path: '../../public/fonts/TT_Commons_Pro_Bold_Italic.woff2',
      weight: 'bold',
      style: 'italic',
    },
  ],
})
import '@/app/globals.css';

import Title from '@/components/ui/Title';
import Lottie from "lottie-react";
import notFound from '@/components/animations/not-found.json';
import { ArrowRight } from 'lucide-react';
import Link from 'next/link';

export default function NotFoundPage() {

  return (
    <html lang="es">
      <body className={`bg-brand-blue-dark ${TTCommons.className} text-white`}>
        <main className="bg-brand-blue-dark relative transition-all min-h-screen w-full mx-auto global-padding flex flex-col gap-y-8 items-center justify-center text-center text-white">
          <div className="h-32 w-32 md:h-56 md:w-56 mx-auto">
            <Lottie animationData={notFound} loop={true} className='object-contain scale-150' />
          </div>
          <Title text={'Página No Encontrada'} />
          <p>Por favor, verifica la barra de direcciones del navegador o usa la navegación para ir a una página conocida</p>
          <Link href={'/'} className="mt-4 w-full rounded-full bg-brand-blue-reg px-8 py-3 text-sm font-bold text-white transition-colors hover:bg-brand-yellow hover:text-brand-blue-dark md:w-fit mx-auto">
            Regresar al Inicio <ArrowRight className="inline" />
          </Link>
        </main>
      </body>
    </html>
  );
}