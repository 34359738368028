import { ReactNode } from "react";
import { ArrowRight as ArrowRightIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Link } from '@/i18n/routing';
import Image from "next/image";

const BentoGrid = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "grid w-full auto-rows-[22rem] grid-cols-3 global-gap-sm",
        className,
      )}
    >
      {children}
    </div>
  );
};

const BentoCard = ({
  name,
  className,
  background,
  Icon,
  IconImg,
  description,
  href,
  cta,
}: {
  name: string;
  className: string;
  background: ReactNode;
  Icon?: any;
  IconImg?: string;
  description: string;
  href?: any;
  cta?: string;
}) => (
  <div
    key={name}
    className={cn(
      "group relative col-span-3 flex flex-col justify-between overflow-hidden rounded-3xl bg-white border border-brand-blue-mid",
      className,
    )}
  >
    <div>{background}</div>
    <div className={`z-10 flex transform-gpu flex-col gap-1 p-6 transition-all duration-300 ${href && cta ? `group-hover:-translate-y-14` : ''}`}>

      {IconImg && (
        <div
          className={`h-12 w-12 mb-3 origin-left transform-gpu transition-all duration-300 ease-in-out ${href && cta ? `group-hover:scale-75` : ''}`}
        >
          <Image
            src={IconImg}
            alt={name}
            fill
          />
        </div>
      )}
      {Icon && (
        <Icon className={`h-12 w-12 origin-left transform-gpu text-brand-blue-mid transition-all duration-300 ease-in-out ${href && cta ? `group-hover:scale-75` : ''}`} />
      )}
      <h3 className="heading-6 text-brand-blue-mid">
        {href ? (
          <Link href={href} className="font-bold">{name}</Link>
        ) : (
          <>{name}</>
        )}

      </h3>
      <p className="max-w-lg xl:max-w-xl text-gray-500 py-2">{description}</p>
    </div>

    {href && cta && (
      <>
        <div
          className={cn(
            "absolute z-10 bottom-0 flex w-full translate-y-10 transform-gpu flex-row items-center p-6 opacity-0 transition-all duration-300 group-hover:translate-y-0 group-hover:opacity-100",
          )}
        >
          <Link href={href} className="flex items-center text-brand-blue-mid font-bold">
            {cta}
            <ArrowRightIcon className="ml-2 h-6 w-6" />
          </Link>
        </div>
        <div className="pointer-events-none absolute inset-0 transform-gpu transition-all duration-300 group-hover:bg-white/[.9]" />
      </>
    )}
  </div>
);

export { BentoCard, BentoGrid };
