import { defineRouting } from 'next-intl/routing';
import { createNavigation } from 'next-intl/navigation';

export const routing = defineRouting({
  locales: ['en', 'es'],
  pathnames: {
    '/': '/',
    // '/about': {
    //   es: '/nosotros',
    //   en: '/about-us',
    // },
    '/websites': {
      es: '/diseno-web-miami',
      en: '/miami-web-design',
    },
    '/portfolio': {
      es: '/portafolio',
      en: '/portfolio',
    },
    '/contact': {
      es: '/contacto',
      en: '/contact',
    },
  },
  defaultLocale: 'es',
  alternateLinks: true
});

export type Pathnames = keyof typeof routing.pathnames;
export type Locale = (typeof routing.locales)[number];

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(routing);