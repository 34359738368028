"use client"
import React, { useEffect, useRef } from "react";
import { useTranslations } from 'next-intl';
import { animate, useInView } from "framer-motion";

export const BrandStats = () => {
  const t = useTranslations('IndexPage');

  return (
    <div className="bg-white w-full">
      <div className="section-base mx-auto global-padding-lg flex flex-col global-gap">
        <h2 className="heading-6 text-center text-brand-blue-mid">
          {t('trackRecord.title')}
        </h2>

        <div className="flex flex-row items-top mx-auto global-gap">
          <Stat
            num={200}
            prefix="+"
            subheading={t('trackRecord.stats.1')}
          />
          <div className="bg-gray-300 h-12 w-[1px]" />
          <Stat
            num={80}
            decimals={1}
            suffix="%"
            subheading={t('trackRecord.stats.2')}
          />
          <div className="bg-gray-300 h-12 w-[1px]" />
          <Stat
            num={10}
            suffix={` ${t('trackRecord.years')}`}
            subheading={t('trackRecord.stats.3')}
          />
        </div>
      </div>
    </div>
  );
};

const Stat = ({
  num,
  prefix,
  suffix,
  decimals = 0,
  subheading,
}: {
  num: number;
  prefix?: string;
  suffix?: string;
  decimals?: number;
  subheading: string;
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (!isInView) return;

    animate(0, num, {
      duration: 2.5,
      onUpdate(value) {
        if (!ref.current) return;
        ref.current.textContent = value.toFixed(decimals);
      },
    });
  }, [num, decimals, isInView]);

  return (
    <div className="flex flex-col">
      <p className="text-center heading-3 text-brand-blue-reg mb-2">
        {prefix && <span className="md:text-3xl font-bold">{prefix}</span>}
        <span className="font-bold" ref={ref}></span>
        {suffix && <span className="md:text-3xl font-bold">{suffix}</span>}
      </p>
      <p className="max-w-52 text-balance text-xs md:text-sm text-center text-gray-500">{subheading}</p>
    </div >
  );
};