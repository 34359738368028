'use client';
import { useLocale, useTranslations } from 'next-intl';

export default function LocaleSwitcher({ showLabel = false }) {
  const locale = useLocale();
  const t = useTranslations('LocaleSwitcher');

  const newLocale = locale === 'en' ? 'es' : 'en';

  function handleClick() {
    const currentPath = window.location.pathname;
    let newPath = currentPath.replace(`/${locale}`, `/${newLocale}`);

    if (currentPath.includes(`/${locale}`)) {
      newPath = currentPath.replace(`/${locale}`, `/${newLocale}`);
      window.location.href = newPath;
    } else {
      window.location.href = `/${newLocale}${currentPath}`;
    }
  }

  return (
    <div className='w-fit h-10 flex items-center text-xs locale-switcher'>
      <label htmlFor="locale-switcher" className={`mr-2 text-white ${showLabel ? '' : 'sr-only'}`}> {t('switchToAlt', { locale: newLocale })}:</label>
      <button
        id="locale-switcher"
        onClick={handleClick}
        className="bg-white hover:bg-brand-yellow text-brand-blue-dark transition-all duration-300 ease-in-out font-bold px-2 py-2 rounded-2xl text-xs uppercase"
        aria-label={t('switchTo', { locale: newLocale })}
        role="switch"
        aria-checked={locale === 'es'}
      >
        {t('switchTo', { locale: newLocale })}
      </button>
    </div>
  );
}
