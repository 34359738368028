'use client';

import { useSelectedLayoutSegment } from 'next/navigation';
import { ComponentProps } from 'react';
import { Pathnames, Link } from '@/i18n/routing';

export default function NavLink<Pathname extends Pathnames>({
  href,
  ...rest
}: ComponentProps<typeof Link> & { href: Pathname }) {
  const selectedLayoutSegment = useSelectedLayoutSegment();
  const pathname = selectedLayoutSegment ? `/${selectedLayoutSegment}` : '/';
  const isActive = pathname === href;

  return (
    <Link
      aria-current={isActive ? 'page' : undefined}
      className={`${isActive ? 'active' : ''}`}
      href={href}
      {...rest}
    />
  );
}
