import { cn } from "@/lib/utils";

interface NotificationType {
  name: string;
  description: string;
  icon: string;
  color: string;
  time: string;
}

const PhoneNotification = ({ name, description, icon, color, time }: NotificationType) => {
  return (
    <figure
      className={
        cn(
          "relative mx-auto min-h-fit w-full max-w-[400px] cursor-pointer overflow-hidden rounded-xl p-4",
          "transition-all duration-200 ease-in-out hover:scale-[103%]",
          "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
          "transform-gpu dark:bg-transparent dark:backdrop-blur-md dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]",
        )
      }
    >
      <div className="flex flex-row items-center gap-3" >
        <div
          className="flex size-10 items-center justify-center rounded-xl"
          style={{
            backgroundColor: color,
          }
          }
        >
          <span className="text-2xl"> {icon} </span>
        </div>
        <div className="flex flex-col overflow-hidden">
          <figcaption className="flex flex-row items-center whitespace-pre text-lg font-medium dark:text-white " >
            <span className="text-sm font-bold text-brand-blue-mid">{name}</span>
            <span className="mx-1">·</span>
            <span className="text-xs text-brand-blue-mid">{time}</span>
          </figcaption>
          <p className="text-xs text-gray-500" >
            {description}
          </p>
        </div>
      </div>
    </figure>
  );
};

export default PhoneNotification;