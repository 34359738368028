import { twMerge } from "tailwind-merge";
import { Link } from '@/i18n/routing';

const GhostButton = ({ href, children, className, ...rest }: { href: any, children: any, className?: string }) => {
  return (
    <Link
      href={href}
      className={
        twMerge(
          "rounded-3xl px-4 py-2 text-white/80 bg-transparent transition-all hover:scale-[1.02] hover:bg-white hover:text-brand-blue-mid active:scale-[0.98] ",
          className
        )
      }
      {...rest}
    >
      {children}
    </Link>
  );
};
export default GhostButton;