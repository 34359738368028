import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3278576974/src/colmena-2024-v2/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3278576974/src/colmena-2024-v2/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3278576974/src/colmena-2024-v2/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3278576974/src/colmena-2024-v2/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--TTCommons\",\"src\":[{\"path\":\"../../../public/fonts/TT_Commons_Pro_Normal.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/TT_Commons_Pro_Normal_Italic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../../public/fonts/TT_Commons_Pro_Bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/TT_Commons_Pro_Bold_Italic.woff2\",\"weight\":\"bold\",\"style\":\"italic\"}]}],\"variableName\":\"TTCommons\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3278576974/src/colmena-2024-v2/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3278576974/src/colmena-2024-v2/src/components/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3278576974/src/colmena-2024-v2/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3278576974/src/colmena-2024-v2/src/components/NavigationV2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3278576974/src/colmena-2024-v2/src/components/ui/ScrollTop.tsx");
